var en = {
    fullName: "English",
    title: "LOCI",
    panels: {
        home: "Home",
        assignments: "Assignments",
        templates: "Templates",
        users: "Users",
        calendar: "Calendar",
        createAssignment: "New assignment",
        editAssignment: "Edit assignment",
        terms: "Terms",
        profile: "Profile",
        company: "My company",
        credits: "Credits",
    },
    assignments: {
        info: "Assignment info",
        addDocument: "Add location description",
        new: "New assignment",
        create: "Create",
        update: "Update",
        name: "Assignment name",
        planDate: "Planned date",
        status: {
            label: "Status",
            PENDING: "Pending",
            DONE: "Done"
        },
        executionDate: "Executed on",
        assignee: "Assigned to",
        collaborators: "Collaborators",
        assign: "Assign",
        unassigned: "Not assigned",
        markPending: "Mark as pending",
        markDone: "Mark as done",
        attributes: {
            name: "Assignment name",
            address: {
                address: "Address",
                city: "City",
                zipCode: "ZIP",
                street: "Street",
                number: "Number",
                bus: "Bus"
            }, planDate: "Planned date",
            executionDate: "Executed on",
            assignee: "Assigned to",
            requiredDocuments: "Location descriptions",
            party: "Party",
            partyName: "Party name",
            partyRole: "Role",
            partyRolePlaceholder: "E.g.: Tenant, owner...",
            partyRolePlaceholder2: "E.g.: Landlord, Building owner",
            representative: "Representative",
            partyInfo: "Extra information"
        },
        mail: {
            title: "Send document",
            to: "To",
            from: "From",
            subject: "Subject",
            message: "Message",
            send: "Send"
        },
        filterMine: "Filter assigned to me",
        noAssignments: "No assignments available",
        assignmentMessage: "Would you like to notify the executor?",
        addParty: "Add party",
        showArchive: "Show archive",
        showActive: "Show active assignments",
        archive: "Archive",
        unarchive: "Unarchive"
    },
    document: {
        title: "Documents",
        notAvailable: "No documents available",
        downloadPDF: "Download PDF",
        downloadDocx: "Download Docx",
        downloadImages: "Download Images as ZIP",
        downloadLastSavedPDF: "Download last saved PDF",
        downloadLastSavedDocx: "Download last saved Docx",
        recencyWarning: "This document may not contain the latest changes. Regenerate the document to implement the latest changes.",
        generating: "Generating document",
        downloadingImages: "Downloading images",
        generatingDocx: "Generating Docx",
        generatingPdf: "Converting to PDF",
        name: "Document name",
        language: "Language",
        template: "Template",
        nullTemplate: "Don't use template",
        locationDescription: "Location description",
        reference: "Your reference",
        generate: "Generate documents",
        intro: "Intro",
        executedBy: "Executed by",
        executedOn: "Executed on",
        sign: "Sign",
        lock: "Lock",
        exportDocx: "Export as .docx (Word)",
        openDocx: "Open as .docx (Word)",
        roles: {
            renter: "Renter",
            rentee: "Rentee",
            executor: "Executor"
        },
        page: "Page",
        of: "of",
        lockConfirmationMessage: "Are you sure you want to lock the document? This is permanent and cannot be undone.",
        previewFormattingMessage: "Report preview. The formatting will change when the document is generated.",
        terms: {
            name: "Name",
            noTermsPlaceholder: "You haven't set general terms yet.",
            noName: "Unnamed terms",
            create: "Click here to create general terms",
            placeholder: "Rent NL",
            addParagraph: "Add paragraph",
            edit: "Edit terms",
            general: "General conditions",
            clauses: "Clauses",
            clauseCostParty: "Extra clause when costs are determined by one party",
            clauseCostSplit: "Extra clause when costs are splitted",
            remarks: "General report remarks",
            signatureTextHeader: "Introduction text for signatures",
            signatureTextItems: "Items to show for signatures",
            regenerate: "Regenerate",
            regenerateConfirmationMessage: "Do you want to regenerate the terms based on the following template? All changers made to this terms will be lost."
        },
        images: "Photos",
        noImages: "No photos available"
    },
    generic: {
        pickLanguage: "Pick your language",
        name: "Name",
        delete: "Delete",
        add: "Add",
        new: "New",
        edit: "Edit",
        save: "Save",
        open: "Open",
        cancel: "Cancel",
        confirm: "Confirm",
        confirmDeletion: "Are you sure you want to delete this items?",
        nameNL: "Dutch name",
        nameFR: "French name",
        loading: "Loading...",
        synchronize: "Synchronize",
        undo: "Undo",
        download: "Download",
        search: "Search",
        showMore: "Show more",
        network: {
            disconnected: "No internet"
        },
        updates: {
            yourVersion: "Your version",
            latestVersion: "Latest version",
            updateAvailable: "A new version of the app is now available",
        },
        manageApp: "Manage App",
        loginToCompany: "Log in to this company's space",
        loginToCompanyInfo: "When you login to this space, you will be signed out. Logging back in, will let you enter this company's space",
        betaWarning: "LOCI is now in Bèta. If you experience any issues, or you have a request, please contact aron.geerts@agits.be.",
        noName: "No name"
    },
    users: {
        unknown: "Unknown user",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        emailExists: "This email address is already in use",
        password: "Password",
        confirmPassword: "Confirm password",
        role: "Role",
        roles: {
            APP_ADMIN: "Application admin",
            ADMIN: "Admin",
            USER: "User",
            ON_HOLD: "On hold"
        },
        login: "Login",
        loginFailed: "Invalid username or password",
        forgotPassword: "Forgot password",
        requestPasswordReset: "Request Password Reset",
        askReset: "Ask for a Reset",
        resetSuccess: "Password Reset Successful",
        resetSuccessDescription: "Your password has been reset successfully.",
        verificationCode: "Verification code",
        goSignin: "To login",
        passwordSent: "Password reset email sent successfully.",
        errorReset: "An unknown error occurred during the password reset confirmation.",
        logout: "Logout",
        create: "New user",
        changePassword: "Change password",
        oldPassword: "Old password",
        newPassword: "New password",
        invalidPasswordFormat: "Invalid password: use at least 8 characters",
        signature: "Signature",
        sign: "Sign",
        editSignature: "Edit signature"
    },
    templates: {
        title: "Templates",
        add: "Add",
        icon: "Icon",
        evaluation: "Evaluation",
        state: "General state",
        multimedia: "Multimedia",
        observation: "Observation",
        addTemplate: "Add template",
        addLevel: "Add item",
        copyFrom: "Copy from",
        noCopy: "Don't copy",
        nl: "Name NL",
        fr: "Name FR",
        requiredEvaluations: "Required data",
        evaluationRequired: "Evaluation",
        stateRequired: "General state",
        multimediaRequired: "Multimedia",
        observationRequired: "Observation",
        editRoom: "Edit item",
        moveUp: "Move to top",
        moveDown: "Move to bottom",
        rooms: "Items",
        noRooms: "No items"
    },
    inspection: {
        start: "Start inspection",
        takePicture: "Add picture",
        copy: "Copy",
        copyStructure: "Copy structure",
        copyStructureWithAttributes: "Copy structure including attributes",
        continue: "Continue",
        generatePDF: "Generate PDF",
        edit: "Edit inspection",
        general: "General",
        copySuffix: "Suffix",
        addAttribute: "Add attribute",
        attributeName: "Attribute",
        attributeValue: "Value",
        imageDescription: "Description",
        costingSystem: "Split costs location description",
        splitCosts: "Splitted",
        objectType: "Property type",
        moveNode: "Move item",
        failedToSave: "The document could not be saved. It may be too big.",
        inspectionType: {
            label: "Inspection type",
            OTHER: "Other",
            BEFORE_WORK: "Before works",
            AFTER_WORK: "After works",
            BEFORE_RENT: "Before rent",
            AFTER_RENT: "After rent"
        },
        state: {
            label: "General state",
            NEW: "New",
            VERY_BAD: "Very bad",
            BAD: "Bad",
            DECENT: "Decent",
            GOOD: "Good",
            VERY_GOOD: "Very good"
        },
        observation: "Observation",
        observations: "Observations",
        addObservation: "Add observation",
        observationType: "Type",
        observationImages: "Observation images",
        remarks: "Remarks",
        addRemark: "Add remark",
        generalImages: "General images",
        overviewImage: "Overview image",
        addImages: "Upload images",
        addRoom: "Add Item",
        rotateRight: "Rotate right",
        rotateLeft: "Rotate left",
        errors: {
            insufficient_credits: "Insufficient credits",
            imagesNotLoaded: "Not all images were loaded correctly. Check if the document was correctly synchronized."
        }
    },
    company: {
        name: "Company name",
        email: "Email",
        address1: "Address line 1",
        address2: "Address line 2",
        phone: "Phone number",
        vat: "VAT",
        web: "Web",
        uploadLogo: "Click here to upload an image or logo for your company",
        registrationTimestamp: "Registered on",
        subscriptionType: "Subscription type",
        registration: {
            errors: {
                length: function (minLength, maxLength) { return "Enter a value with ".concat(minLength, " to ").concat(maxLength, " characters"); },
                email: "Enter a valid email address",
                phone: "Enter a valid phone number",
                vat: "Enter a valid VAT number. Format: BE0999999999, NL123456789B01 or FR12345678901",
                invalidCode: "Invalid company registration code"
            },
            seeCompanyEmail: "Your company was successfully registered. Confirm your company email address using the link in the email we sent you.",
            companySuccessful: "Your company registration was confirmed! Create a user to get started!",
            seeUserEmail: "Thank you for your registration. Please, confirm your user email address using the link in your email.",
            resendEmailMessage: "If you did not receive an email. check your spam or contact aron.geerts@agits.be",
            resendEmail: "Resend email",
        }
    },
    portal: {
        downloadApp: "Download the app",
        pricing: "Pricing",
        documentation: "Manual",
        free: "Free",
        pro: "Professional",
        contact: {
            title: "Contact",
            email: "Your e-mailadress",
            message: "Message",
            send: "Send"
        },
        contactReceived: "Thank you for your message! We will get back to you soon!",
        signup: "Sign up",
        readAndAccepted: "By signing up, I confirm to have read and accepted the",
        termsAndConditions: "Terms and Conditions",
        pricingPerItem: function (price) { return "\u20AC".concat(price, " per report"); },
        freeTrialFeatures: [
            "1 month free trial",
            "4 free digital location reports"
        ],
        excludedFreeTrialFeatures: [
            "Unlimited number of users",
        ],
        professionalFeatures: [
            "Create digital location reports",
            "Unlimited number of users",
            "Unlimited cloud storage"
        ],
        freeTrial: "Free trial",
        startUpFee: function (price) { return "\u20AC".concat(price, " start-up fee"); },
        tryNow: "Try now",
        intro: [
            "Fast, efficiënt en complete creation of location descriptions?\nStart your free trial now, or subscribe right away.",
            "With the mobile application, your team is able to create location descriptions on site en generate reports automatically.\nAfter, you can share these within your team en your customers",
            "The application is available in French, Dutch or English."
        ]
    },
    credits: {
        title: "Credits",
        yourBalance: "Your current balance",
        yourNewBalance: "Your new balance",
        transactions: "Transactions",
        buyConfirmation: "Creating this inspection will result in a credit being deducted from your balance. Are you sure you want to continue?",
        amount: "Number",
        price: "Price",
        credits: "Credits",
        buyCredits: "Buy credits",
        excludingVAT: "Excluding VAT",
        successfulTransaction: "Order placed! You will receive an email confirmation on your company email address. Your balance will be updated in a minute!",
        failedTransaction: "Something went wrong processing your payment. Don't worry, no money was deducted from your account",
        transactionType: {
            label: "Type",
            USE: "Inspection purchase",
            PURCHASE: "Payment",
            REGISTRATION: "Registration"
        },
        freeTrialExpired: "Your free trial has expired",
        freeTrialExpiring: function (nbDays) { return "Your free trial is expiring in ".concat(nbDays, " days"); },
        upgradeSubscription: "Upgrade"
    }
};
export default en;
