var fr = {
    fullName: "Français",
    title: "LOCI",
    panels: {
        home: "Accueil",
        assignments: "Missions",
        templates: "Templates",
        users: "Utilisateurs",
        calendar: "Calendrier",
        createAssignment: "Nouvelle mission",
        editAssignment: "Modifier la mission",
        terms: "Dispositions",
        profile: "Profile",
        company: "Mon entreprise",
        credits: "Credits"
    },
    assignments: {
        info: "Information de la mission",
        addDocument: "Ajouter une description de lieux",
        new: "Nouvelle mission",
        create: "Créer",
        update: "Modifier",
        name: "Nom de la mission",
        planDate: "Date prévue",
        status: {
            label: "État",
            PENDING: "En cours",
            DONE: "Finalisé"
        },
        executionDate: "Réalisé sur",
        assignee: "Assigné à",
        collaborators: "Collaborateurs",
        assign: "Assigner",
        unassigned: "Non assigné",
        markPending: "Marquer comme non terminé",
        markDone: "Marquer comme terminé",
        attributes: {
            name: "Nom de la mission",
            address: {
                address: "Adresse",
                city: "Ville",
                zipCode: "Code postal",
                street: "Rue",
                number: "Numéro",
                bus: "Numéro de boîte"
            },
            planDate: "Date prévue",
            executionDate: "Réalisé sur",
            assignee: "Assigné à",
            requiredDocuments: "Descriptions de lieux",
            party: "Partie",
            partyName: "Nom de la partie",
            partyRole: "Rôle",
            partyRolePlaceholder: "Par ex. Locataire, propriétaire...",
            partyRolePlaceholder2: "Par ex. Bailleur, maître d'ouvrage",
            representative: "Représentant",
            partyInfo: "Information supplémentaire"
        },
        mail: {
            title: "Envoyer le document",
            to: "À",
            from: "De",
            subject: "Sujet",
            message: "Message",
            send: "Envoyer"
        },
        filterMine: "Filtrer assigné à moi",
        noAssignments: "Aucune mission disponible",
        assignmentMessage: "Voulez-vous envoyer un message & l'exécuteur?",
        addParty: "Ajouter une partie",
        showArchive: "Voir archives",
        showActive: "Voir missions actives",
        archive: "Archiver",
        unarchive: "Déarchiver"
    },
    document: {
        title: "Documents",
        notAvailable: "Aucun document disponible",
        downloadPDF: "Télécharger PDF",
        downloadDocx: "Télécharger Docx",
        downloadImages: "Télécharger images",
        downloadLastSavedPDF: "Télécharger le dernier PDF enregistré",
        downloadLastSavedDocx: "Télécharger le dernier Docx enregistré",
        recencyWarning: "Ce document peut ne pas contenir les dernières modifications. Régénérez le document pour implémenter les dernières modifications.",
        generating: "Générer le document",
        downloadingImages: "Télécharger les images",
        generatingDocx: "Générer Docx",
        generatingPdf: "Converter à PDF",
        name: "Nom du document",
        language: "Langue",
        template: "Modèle",
        nullTemplate: "Ne pas utiliser de modèle",
        locationDescription: "Description de lieux locative",
        reference: "Votre référence",
        generate: "Générer les documents",
        intro: "Intro",
        executedBy: "Réalisé par",
        executedOn: "Réalisé sur",
        sign: "Signer",
        lock: "Fixer",
        exportDocx: "Exporter comme .docx (Word)",
        openDocx: "Ouvrir comme .docx (Word)",
        roles: {
            renter: "Locataire",
            rentee: "Propriétaire",
            executor: "Exécuteur"
        },
        page: "Page",
        of: "de",
        lockConfirmationMessage: "Êtes-vous sure que vous voelen fixer le document? Cette action est definitive et irréversible.",
        previewFormattingMessage: "Aperçu du rapport. La mise en forme sera encore modifiée lorsque le document sera généré.",
        terms: {
            name: "Nom",
            noTermsPlaceholder: "Vous n'avez pas encore défini de dispositions générales",
            noName: "Dispositions sans nom",
            create: "Cliquez ici pour rédiger une disposition générale",
            placeholder: "Location NL",
            addParagraph: "Ajouter un paragraphe",
            edit: "Éditer les dispositions",
            general: "Conditions générales",
            clauses: "Clauses",
            clauseCostParty: "Clause additionnelle en cas de prise en charge des frais par une seule partie",
            clauseCostSplit: "Clause additionnelle en cas de partage des coûts",
            remarks: "Remarque générales pour le rapport",
            signatureTextHeader: "Texte introductif accompagnant la déclaration des signatures",
            signatureTextItems: "Éléments à afficher avec les signatures",
            regenerate: "Régénérer",
            regenerateConfirmationMessage: "Souhaitez-vous régénérer les dispositions sur la base du modèle suivant ? Toutes les modifications apportées  seront perdues."
        },
        images: "Photos",
        noImages: "Pas de photos"
    },
    generic: {
        pickLanguage: "Choisissez votre langue",
        name: "Nom",
        delete: "Supprimer",
        add: "Ajouter",
        new: "Nouveau",
        edit: "Éditer",
        save: "Sauvegarder",
        open: "Ouvrir",
        cancel: "Annuler",
        confirm: "Confirmer",
        confirmDeletion: "Êtes-vous sûr que vous voulez supprimer cet élément ?",
        nameNL: "Nom néerlandais",
        nameFR: "Nom français",
        loading: "En charge...",
        synchronize: "Synchroniser",
        undo: "Défaire",
        download: "Télécharger",
        search: "Chercher",
        showMore: "Montrer plus",
        network: {
            disconnected: "Déconnecté"
        },
        updates: {
            yourVersion: "Votre version",
            latestVersion: "Nouvelle version",
            updateAvailable: "Une nouvelle version de l'application est maintenant disponible.",
        },
        manageApp: "Gérer l'application",
        loginToCompany: "Connectez-vous à l'espace de cette entreprise",
        loginToCompanyInfo: "Lorsque vous vous connectez à cet espace, vous serez déconnecté. Vous pourrez entrer dans l'espace de cette entreprise en vous reconnectant.",
        betaWarning: "LOCI est maintenant en version bêta. Si vous rencontrez des problèmes ou si vous avez une demande, veuillez contacter aron.geerts@agits.be.",
        noName: "Pas de nom"
    },
    users: {
        unknown: "Utilisateur inconnu",
        firstName: "Prénom",
        lastName: "Nom",
        email: "Email",
        emailExists: "L'adresse e-mail est déjà utilisée.",
        password: "Mot de passe",
        confirmPassword: "Confirmer le mot de passe",
        role: "Rôle",
        roles: {
            APP_ADMIN: "Administrateur de l'application",
            ADMIN: "Administrateur",
            USER: "Utilisateur",
            ON_HOLD: "En attente"
        },
        login: "Se connecter",
        loginFailed: "Nom d'utilisateur ou mot de passe invalide",
        forgotPassword: "Oublié le mot de passe",
        requestPasswordReset: "Demander la réinitialisation du mot de passe",
        askReset: "Demander une réinitialisation",
        resetSuccess: "Réinitialisation du mot de passe réussie",
        resetSuccessDescription: "Votre mot de passe a été réinitialisé avec succès.",
        verificationCode: "Code vérification",
        goSignin: "Connexion",
        passwordSent: "Email de réinitialisation du mot de passe envoyé avec succès",
        errorReset: "Une erreur inconnue s'est produite lors de la confirmation de la réinitialisation du mot de passe.",
        logout: "Se déconnecter",
        create: "Nouvel utilisateur",
        changePassword: "Modifier le mot de passe",
        oldPassword: "Ancien mot de passe",
        newPassword: "Nouveau mot de passe",
        invalidPasswordFormat: "Mot de passe invalide: choisissez 8 caractères au minimum",
        signature: "Signature",
        sign: "Signer",
        editSignature: "Éditer la signature"
    },
    templates: {
        title: "Modèles",
        add: "Ajouter",
        icon: "Symbole",
        evaluation: "Évaluation",
        state: "État générale",
        multimedia: "Multimedia",
        observation: "Observation",
        addTemplate: "Ajouter un modèle",
        addLevel: "Ajouter un élément",
        copyFrom: "Copier de",
        noCopy: "Ne pas copier",
        nl: "Nom NL",
        fr: "Nom FR",
        requiredEvaluations: "Informations demandées",
        evaluationRequired: "Évaluation",
        stateRequired: "État général",
        multimediaRequired: "Multimedia",
        observationRequired: "Observation",
        editRoom: "Éditer l'élément",
        moveUp: "Déplacer vers le haut",
        moveDown: "Déplacer vers le bas",
        rooms: "Éléments",
        noRooms: "Ne pas d'éléments"
    },
    inspection: {
        start: "Commencer l'inspection",
        takePicture: "Ajouter une photo",
        copy: "Copier",
        copyStructure: "Copier la structure",
        copyStructureWithAttributes: "Copier la structure attributs inclus",
        continue: "Suivre",
        generatePDF: "Générer un PDF",
        edit: "Éditer l'inspection",
        general: "Général",
        copySuffix: "Suivi",
        addAttribute: "Ajouter un attribut",
        attributeName: "Attribut",
        attributeValue: "Valeur",
        imageDescription: "Description",
        costingSystem: "Répartition des coûts de la description de lieux",
        splitCosts: "Partagé",
        objectType: "Type de l'immobilier",
        moveNode: "Déplacer",
        failedToSave: "Le document n'a pas pu être enregistré. Il est possible qu'il soit trop volumineux.",
        inspectionType: {
            label: "Type de l'inspection",
            OTHER: "Autre",
            BEFORE_WORK: "Avant le travaux",
            AFTER_WORK: "Après les travaux",
            BEFORE_RENT: "Avant la location",
            AFTER_RENT: "Après la location"
        },
        state: {
            label: "État général",
            NEW: "Nouveau",
            VERY_BAD: "Très mauvais",
            BAD: "Mauvais",
            DECENT: "Médiocre",
            GOOD: "Bon",
            VERY_GOOD: "Très bon"
        },
        observation: "Observation",
        observations: "Observations",
        addObservation: "Ajouter observation",
        observationType: "Type",
        observationImages: "Images d'observation",
        remarks: "Remarques",
        addRemark: "Ajouter remarques",
        generalImages: "Images générales",
        overviewImage: "Image globale",
        addImages: "Ajouter des images",
        addRoom: "Ajouter un élément",
        rotateRight: "Tourner à droite",
        rotateLeft: "Tourner à gauche",
        errors: {
            insufficient_credits: "Crédits insuffisants",
            imagesNotLoaded: "Pas toutes les images n'ont été chargées. Vérifiez si tout est correctement synchronisée."
        }
    },
    company: {
        name: "Nom de l'entreprise",
        email: "Email",
        address1: "Ligne d'adresse 1",
        address2: "Ligne d'adresse 2",
        phone: "Numéro de téléphone",
        vat: "TVA",
        web: "Web",
        uploadLogo: "Cliquez ici pour télécharger une image ou un logo de votre entreprise",
        registrationTimestamp: "Enrégistré à",
        subscriptionType: "Type de subscription",
        registration: {
            errors: {
                length: function (minLength, maxLength) { return "Veuillez saisir une valeur entre ".concat(minLength, " et ").concat(maxLength, " caract\u00E8res."); },
                email: "Veuillez saisir une adresse e-mail valide",
                phone: "Veuillez saisir un numéro de téléphone valide",
                vat: "Veuillez saisir un numéro de TVA valide. Format : BE0999999999, NL123456789B01 ou FR12345678901.",
                invalidCode: "Code d'inscription invalide.",
            },
            seeCompanyEmail: "Votre entreprise a été enregistrée avec succès. Veuillez confirmer votre adresse e-mail d'entreprise en utilisant le lien dans l'e-mail que nous vous avons envoyé.",
            companySuccessful: "L'inscription de votre entreprise a été confirmée ! Créez un utilisateur pour commencer !",
            seeUserEmail: "Merci pour votre inscription. Veuillez confirmer votre adresse e-mail d'utilisateur en utilisant le lien dans votre e-mail.",
            resendEmail: "Renvoyer l'e-mail",
            resendEmailMessage: "Si vous n'avez pas reçu d'e-mail, voyez dans votre spam ou contactez aron.geerts@agits.be."
        }
    },
    portal: {
        downloadApp: "Téléchargez l'app",
        documentation: "Manuel",
        pricing: "Prix",
        free: "Gratuit",
        pro: "Professionnel",
        contact: {
            title: "Contact",
            email: "Votre Adress e-mail",
            message: "Message",
            send: "Envoyer"
        },
        contactReceived: "Merci pour votre message! Nous vous contactons aussi vite que possible.",
        signup: "Inscription",
        readAndAccepted: "En m'inscrivant, je confirme avoir lu et accepté les",
        termsAndConditions: "Conditions Générales",
        pricingPerItem: function (price) { return "\u20AC".concat(price, " par rapport"); },
        freeTrialFeatures: [
            "1 mois d' essai gratuit",
            "4 rapports d'état des lieux digitals gratuits"
        ],
        excludedFreeTrialFeatures: [
            "Nombre d'utilisateurs illimité",
        ],
        professionalFeatures: [
            "Rapports d'état des lieux digitals",
            "Nombre d'utilisateurs illimité",
            "Espace de stockage illimité"
        ],
        freeTrial: "Essai gratuit",
        startUpFee: function (price) { return "\u20AC".concat(price, " frais de lancement"); },
        tryNow: "Essayez",
        intro: [
            "Établir des descriptions de lieux complètes de façon rapide et efficace?\nCommencez votre période d’essai aujourd’hui ou inscrivez-vous immédiatement.",
            "Cette application mobile vous permet d’établir les descriptions de lieux sur place et de générer des rapports automatiquement.\nEnsuite, vous pouvez les partager dans votre équipe ou avec vos clients.",
            "L’application est disponible en français, en anglais et en néerlandais",
        ]
    },
    credits: {
        title: "Crédits",
        yourBalance: "Votre solde actuel",
        yourNewBalance: "Votre nouveau solde",
        transactions: "Transactions",
        buyConfirmation: "La création de cette inspection entraînera un débit de votre solde. Êtes-vous sûr de vouloir continuer ?",
        amount: "Montant",
        price: "Prix",
        credits: "Crédits",
        buyCredits: "Acheter des crédits",
        excludingVAT: "Sans TVA",
        successfulTransaction: "Commande passée! Vous recevrez un e-mail de confirmation sur l'adresse e-mail de votre entreprise. Votre solde sera mis à jour dans une minute !",
        failedTransaction: "Une erreur s'est produite lors du traitement de votre paiement. Ne vous inquiétez pas, aucun argent n'a été déduit de votre compte",
        transactionType: {
            label: "Type",
            USE: "Achat d'inspection",
            PURCHASE: "paiement",
            REGISTRATION: "Registration"
        },
        freeTrialExpired: "Votre essai gratuit a expiré",
        freeTrialExpiring: function (nbDays) { return "Votre essai gratuit expire dans  ".concat(nbDays, " jours"); },
        upgradeSubscription: "Upgrade"
    }
};
export default fr;
