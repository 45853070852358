var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import InspectionCostingSystem from "../model/inspection/InspectionCostingSystem";
import { getDateString } from "./utils";
export function getChildNode(parent, path) {
    while (path.length > 0) {
        var index = path[0];
        var node = parent.children[index];
        return getChildNode(node, path.slice(1));
    }
    ;
    return parent;
}
;
export function addChildNode(parent, node, path) {
    var index = path[0];
    if (path.length === 1) {
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), [node], false), parent.children.slice(index), true) });
    }
    else {
        var child = parent.children[index];
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), [
                addChildNode(__assign({}, child), node, path.slice(1))
            ], false), parent.children.slice(index + 1), true) });
    }
}
;
export function deleteChildNode(parent, path) {
    var index = path[0];
    if (path.length === 1) {
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray([], parent.children.slice(0, index), true), parent.children.slice(index + 1, parent.children.length), true) });
    }
    else {
        var child = parent.children[index];
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), [
                deleteChildNode(child, path.slice(1))
            ], false), parent.children.slice(index + 1), true) });
    }
}
;
export function updateChildNode(parent, node, path) {
    if (path.length === 0) {
        return node;
    }
    var index = path[0];
    if (path.length === 1) {
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), [
                node
            ], false), parent.children.slice(index + 1, parent.children.length), true) });
    }
    else {
        var child = parent.children[index];
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), [
                updateChildNode(child, node, path.slice(1))
            ], false), parent.children.slice(index + 1), true) });
    }
}
;
export function moveChildNodeUp(parent, path) {
    var index = path[0];
    var node = parent.children[index];
    if (path.at(-1) === 0) {
        return parent;
    }
    else if (path.length === 1) {
        var above = parent.children.at(index - 1);
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index - 1), true), [
                node,
                above
            ], false), parent.children.slice(index + 1), true) });
    }
    else {
        parent.children[index] = moveChildNodeUp(node, path.slice(1));
        return parent;
    }
}
;
export function moveChildNodeDown(parent, path) {
    var index = path[0];
    var node = parent.children[index];
    if (path.at(-1) === parent.children.length - 1) {
        return parent;
    }
    else if (path.length === 1) {
        var below = parent.children.at(index + 1);
        return __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), [
                below,
                node
            ], false), parent.children.slice(index + 2), true) });
    }
    else {
        parent.children[index] = moveChildNodeDown(node, path.slice(1));
        return parent;
    }
}
;
export function addNode(inspection, node, path) {
    return __assign(__assign({}, inspection), { root_node: addChildNode(inspection.root_node, node, path) });
}
;
export function deleteNode(inspection, path) {
    return __assign(__assign({}, inspection), { root_node: deleteChildNode(inspection.root_node, path) });
}
;
export function updateNode(inspection, node, path) {
    return __assign(__assign({}, inspection), { root_node: updateChildNode(inspection.root_node, node, path) });
}
;
export function moveNodeUp(inspection, path) {
    return __assign(__assign({}, inspection), { root_node: moveChildNodeUp(inspection.root_node, path) });
}
;
export function moveNodeDown(inspection, path) {
    return __assign(__assign({}, inspection), { root_node: moveChildNodeDown(inspection.root_node, path) });
}
;
export function copyInspectionNode(node, name, imageCopy) {
    var _a;
    var images = __spreadArray([], node.image_references, true);
    var observations = __spreadArray([], node.observations, true);
    if (imageCopy !== undefined) {
        images = images.map(function (i) {
            var _a, _b;
            return (__assign(__assign({}, i), { copied_from_assignment_id: (_a = i.copied_from_assignment_id) !== null && _a !== void 0 ? _a : imageCopy.assignmentId, copied_from_inspection_id: (_b = i.copied_from_inspection_id) !== null && _b !== void 0 ? _b : imageCopy.inspectionId, date: imageCopy.date }));
        });
        observations = observations.map(function (o) { return (__assign(__assign({}, o), { images: o.images.map(function (i) {
                var _a, _b;
                return (__assign(__assign({}, i), { copied_from_assignment_id: (_a = i.copied_from_assignment_id) !== null && _a !== void 0 ? _a : imageCopy.assignmentId, copied_from_inspection_id: (_b = i.copied_from_inspection_id) !== null && _b !== void 0 ? _b : imageCopy.inspectionId, date: imageCopy.date }));
            }) })); });
    }
    return __assign(__assign({}, node), { name: name !== null && name !== void 0 ? name : node.name, children: node.children.map(function (node) { return copyInspectionNode(node, undefined, imageCopy); }), remarks: (_a = node.remarks) !== null && _a !== void 0 ? _a : [], image_references: images, observations: observations });
}
;
export function copyInspectionNodeStructure(node, name) {
    return __assign(__assign({}, node), { name: name !== null && name !== void 0 ? name : node.name, children: node.children.map(function (node) { return copyInspectionNodeStructure(node, undefined); }), attributes: node.attributes.map(function (attribute) { return ({
            name: attribute.name,
            value: ""
        }); }), state: null, image_references: [], remarks: [], observations: [] });
}
;
export function copyInspectionNodeStructureWithAttributes(node, name) {
    return __assign(__assign({}, node), { name: name !== null && name !== void 0 ? name : node.name, children: node.children.map(function (node) { return copyInspectionNodeStructureWithAttributes(node, undefined); }), state: null, image_references: [], remarks: [], observations: [] });
}
;
export function getName(node, language) {
    return node.name;
}
;
export function renderTerms(terms, inspection, assignment, company, executor) {
    if (!terms) {
        return {
            general: [],
            remarks: [],
            clauses: [],
            clause_cost_party: "",
            clause_cost_split: "",
            signature_text_header: "",
            signature_text_items: []
        };
    }
    return Object.fromEntries(Object.entries(terms).map(function (_a) {
        var key = _a[0], value = _a[1];
        return [key, renderTermsItem(value, inspection, assignment, company, executor)];
    }));
}
;
export function moveInspectionNode(root, identifier, newIdentifier) {
    var _a, _b;
    var node = getChildNode(root, identifier);
    // if same parent, adding a node, will make newIdentifier wrong
    var parentId = identifier.slice(0, -1);
    var parent = getChildNode(root, parentId);
    var oldIndex = (_a = identifier.at(-1)) !== null && _a !== void 0 ? _a : 0;
    var newIndex = (_b = newIdentifier.at(-1)) !== null && _b !== void 0 ? _b : 0;
    if (identifier.slice(0, newIdentifier.length).toString() === newIdentifier.toString()) {
        // Node is dropped into its own (grand)parent
        var newRoot = deleteChildNode(root, identifier);
        return addChildNode(newRoot, node, newIdentifier);
        //props.setTemplate({...props.template, root: addChildNode(newRoot, node, newIdentifier)})
    }
    else if (
    // Two nodes are swapped within the samen parent
    newIdentifier.slice(0, -1).toString() === identifier.slice(0, -1).toString() &&
        newIndex < oldIndex) {
        var newParent = __assign(__assign({}, parent), { children: __spreadArray(__spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, newIndex), true), [
                node
            ], false), parent.children.slice(newIndex, oldIndex), true), parent.children.slice(oldIndex + 1), true) });
        return updateChildNode(root, newParent, parentId);
    }
    else {
        var newRoot = addChildNode(root, node, newIdentifier);
        return deleteChildNode(newRoot, identifier);
    }
}
export function renderTermsItem(value, inspection, assignment, company, executor) {
    var _a, _b, _c;
    if (value === null || value === undefined) {
        return "";
    }
    else if (Array.isArray(value)) {
        return value.map(function (i) { return renderTermsItem(i, inspection, assignment, company, executor); });
    }
    else {
        var renderAddress = (_a = inspection === null || inspection === void 0 ? void 0 : inspection.address) !== null && _a !== void 0 ? _a : assignment.address;
        var address = "".concat(renderAddress.street, " ").concat(renderAddress.number).concat(renderAddress.bus ? "/" + renderAddress.bus : "", ", ").concat(renderAddress.zip_code, " ").concat(renderAddress.city);
        return (value.replaceAll("$ADDRESS1", address)
            .replaceAll("$DATE", getDateString(assignment.plan_date))
            .replaceAll("$ROLE_PARTY1", inspection.party1.role)
            .replaceAll("$ROLE_PARTY2", inspection.party2.name)
            .replaceAll("$REPRESENTATIVE_PARTY1", (_b = inspection.party1.representative) !== null && _b !== void 0 ? _b : "")
            .replaceAll("$REPRESENTATIVE_PARTY2", (_c = inspection.party2.representative) !== null && _c !== void 0 ? _c : "")
            .replaceAll("$PARTY1", inspection.party1.name)
            .replaceAll("$PARTY2", inspection.party2.name)
            .replaceAll("$REPORT_TYPE", inspection.inspection_type)
            .replaceAll("$ROLE_PARTY_COSTS", inspection.costing_system === InspectionCostingSystem.PARTY1 ? (inspection.party1.role) : (inspection.costing_system === InspectionCostingSystem.PARTY2 ? inspection.party2.role : ""))
            .replaceAll("$COMPANY_NAME", company.name)
            .replaceAll("$EXECUTOR", executor));
    }
}
