import { Button, Snackbar, Stack, Typography } from "@mui/material";
import { GetInspectionResponse } from "inspector-common/lib/api/calls";
import { useGetAssignment, useGetInspection, useUpdateInspection } from "inspector-common/lib/api/hooks";
import { Assignment, Company, Inspection, InspectionNode } from "inspector-common/lib/model";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom"
import InspectionPageContainer from "../../assignments/components/container/InspectionPageContainer";
import DocumentDownload from "../../assignments/components/inspections/DocumentDownload";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import { useLanguage } from "inspector-common/lib/language";
import InspectionInfo from "../components/InspectionInfo";
import InspectionDetails from "../components/InspectionDetails";
import ImageGrid from "../components/ImageGrid";
import DocumentGenerationDialog from "../components/DocumentGenerationDialog";
import FloatingButtons from "../components/FloatingButtons";
import { useAuth } from "../../../auth";
import InspectionTerms from "../components/InspectionTerms";
import { useCompany } from "../../company/hooks/company";
import { Spinner } from "../../../components";
import { getDocumentName } from "../functions";
import { addNode, deleteNode, getChildNode, moveInspectionNode, moveNodeDown, moveNodeUp, updateNode } from "inspector-common/lib/utils/inspection";
import { useUserName } from "../../../state";
import MoveDialog from "../components/MoveDialog";
import { useTemplates } from "../../templates/functions";
import AddInspectionNodeDialog from "../components/AddInspectionNodeDialog";

interface InspectionPageProps {};

const styles = {
    title: {
        marginTop: 3
    },
    titleStack: {
        alignItems: "center",
        justifyContent: "space-between"
    }
}

export default function InspectionPage(props: InspectionPageProps) {
    const match = useMatch("/assignments/edit/:assignmentId/inspections/:inspectionId")
    const assignmentId = match?.params.assignmentId
    const inspectionId = match?.params.inspectionId
    const language = useLanguage();
    const auth = useAuth();

    const navigate = useNavigate();
    
    const { execute: getInspection } = useGetInspection();
    const { execute: getAssignment } = useGetAssignment();
    const { execute: updateInspection } = useUpdateInspection();

    const [assignment, setAssignment] = useState<Assignment | null>(null);
    const [inspection, setInspection] = useState<Inspection | null>(null);
    const [isMovingNode, setIsMovingNode] = useState<number[]>([]);
    const [addNodeOpen, setAddNodeOpen] = useState<boolean>(false);
    const [error, setError] = useState(false);

    const { company } = useCompany();

    const [saving, setSaving] = useState<boolean>(false);
    const [documentGenerationOpen, setDocumentGenerationOpen] = useState<boolean>(false);
    const userName = useUserName(assignment?.assignee) ?? auth.config.userFullName

    const { templates } = useTemplates();

    useEffect(() => {
        if (inspectionId && assignmentId) {
            getAssignment({identifier: assignmentId}).then((response) => {
                if (response?.assignment !== null) (
                    setAssignment(response?.assignment as Assignment)
                )
            }).catch(() => {
                navigate("/assignments")
            });
            getInspection({assignment_id: assignmentId, inspection_id: inspectionId}).then((response: GetInspectionResponse | null) => {
                if (response?.inspection !== null) {
                    setInspection(response?.inspection as Inspection);
                }
            }).catch(() => {
                navigate("/assignments")
            });
        }
    }, [inspectionId, assignmentId]);

    if (!inspectionId || !assignmentId || !inspection || !assignment || !company) {
        return <Spinner />
    };

    const images = inspection.root_node.image_references;
    function setImages(images: InspectionImage[]) {
        if (inspection) {
            setInspection({
                ...inspection,
                root_node: {
                    ...inspection.root_node,
                    image_references: images
                }
            })
        }
    };

    function getAllImagesRecursive(inspectionNode: InspectionNode): Array<InspectionImage> {
        var images = inspectionNode.image_references;
        inspectionNode.observations.forEach((observation) => {
            images = images.concat(observation.images);
        })
        inspectionNode.children.forEach((child: InspectionNode) => {
            images = images.concat(getAllImagesRecursive(child));
        })
        return images;
    };
    const allImages = getAllImagesRecursive(inspection.root_node)

    const updateInspectionNode = (node: InspectionNode, path: number[]) => setInspection(
        updateNode(inspection, node, path)
    );
    const deleteInspectionNode = (path: number[]) => setInspection(
        deleteNode(inspection, path)
    );
    const moveInspectionNodeUp = (path: number[]) => setInspection(
        moveNodeUp(inspection, path)
    );
    const moveInspectionNodeDown = (path: number[]) => setInspection(
        moveNodeDown(inspection, path)
    );
    const moveNode = (path: number[]) => {

        setIsMovingNode(path);    
    }

    const addInspectionNode = (path: number[], node: InspectionNode,) => setInspection(
        addNode(inspection, node, path)
    )

    return <InspectionPageContainer>
        <Stack direction="row" sx={styles.titleStack}>
            <Typography variant="h1">{inspection.name}</Typography>
        </Stack>
        <DocumentDownload 
            docxName={getDocumentName(inspection, ".docx")}
            assignmentId={assignmentId} 
            inspectionId={inspectionId} 
            images={allImages}
        />
        <DocumentGenerationDialog
            assignment={assignment}
            inspection={inspection}
            open={documentGenerationOpen}
            setOpen={setDocumentGenerationOpen}
        />

        <InspectionInfo assignment={assignment} inspection={inspection} setInspection={setInspection}/>

        <InspectionTerms
            inspection={inspection}
            setInspection={setInspection}
            assignment={assignment}
            company={company as Company}
            executor={userName}
        />
        
        {
            inspection.root_node.children.map((child: InspectionNode, index) => (
                <InspectionDetails
                    key={index.toString()}
                    path={[index]}
                    node={child}
                    assignmentId={assignmentId}
                    inspectionId={inspectionId}
                    templates={templates}
                    updateNode={updateInspectionNode}
                    deleteNode={deleteInspectionNode}
                    moveNodeUp={moveInspectionNodeUp}
                    moveNodeDown={moveInspectionNodeDown}
                    moveNode={moveNode}
                    addNode={addInspectionNode}
                    imagesLabel={language.inspection.generalImages}
                />
            ))
        }

        <Button onClick={() => setAddNodeOpen(true)}>
            {language.inspection.addRoom}
        </Button>
        <Typography variant="h5" sx={styles.title}>{language.document.images}</Typography>
        <ImageGrid
            assignmentId={assignmentId}
            inspectionId={inspectionId}
            images={images}
            setImages={(images: InspectionImage[]) => setImages(images)}
        />
        <FloatingButtons
            saving={saving}
            openGeneration={() => setDocumentGenerationOpen(true)}
            saveInspection={() => {
                setSaving(true);
                setError(false);
                updateInspection({inspection, assignment_id: assignmentId}).catch(() => setError(true)).finally(() => setSaving(false));
            }}
        />
        <MoveDialog open={isMovingNode.length > 0} setOpen={() => setIsMovingNode([])} moveNode={
            (newParentPath: number[]) => {
                const newParent = getChildNode(inspection.root_node, newParentPath);
                const newPath = newParentPath.concat(newParent.children.length)
                setInspection(
                    {...inspection, root_node: moveInspectionNode(inspection.root_node, isMovingNode, newPath)}
                )
            }
        } inspection={inspection} 
        movingPath={isMovingNode}/>
        <AddInspectionNodeDialog
            open={addNodeOpen} 
            templates={templates} 
            node={inspection.root_node} 
            updateNode={(node: InspectionNode) => setInspection({
                ...inspection,
                root_node: node
            })}
            setOpen={setAddNodeOpen}
        />
        {error ? <Snackbar 
            open={error}
            onClose={() => setError(false)}
            color="error"
            message={language.inspection.failedToSave}
            autoHideDuration={5000}
        /> : null}
    </InspectionPageContainer>
};
